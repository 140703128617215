<template>
  <v-container>
    <v-row>
      <v-btn color="blue-grey lighten-3" depressed @click="dialogTest = true">TEST MESSAGE</v-btn>
      <v-spacer />
      <!-- <v-btn color="blue-grey lighten-3"
        depressed
        @click="dialogTestByMSISDN = true">TEST MESSAGE</v-btn> -->
      <v-spacer />
      <v-btn color="red darken-3" outlined :to="{ name: 'jobs' }">CANCEL</v-btn>
      <v-btn class="mx-5" color="light-blue darken-1" outlined @click.prevent="create"
        >CREATE</v-btn
      >
      <v-dialog v-model="dialogTest" width="500">
        <v-card>
          <v-card-title>Push Message Test</v-card-title>
          <v-divider />
          <v-card-text>
            <v-row class="mt-5">
              <v-text-field
                v-model="digitalId"
                label="Digital ID"
                outlined
                dense
                required
                hint="Support Digital ID Only"
                persistent-hint
              />
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="red darken-3" outlined @click="dialogTest = false">CANCEL</v-btn>
            <v-btn class="mx-5" color="light-blue darken-1" outlined @click="createJobTest"
              >TEST PUSH MESSAGE</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'btnJobAction',
  props: {
    jobInfo: {
      type: Object,
    },
  },
  data: () => ({
    dialogTest: false,
    dialogTestByMSISDN: false,
    digitalId: '',
    rules: {
      phoneFormat: (value) => {
        const pattern = /(?:66[0-9]{9}(?:,|$))+$/;

        return pattern.test(value) || 'Please, check Format Phone No is 66XXXXXXXXX not 0XXXXXXXXX';
      },
    },
  }),

  // watch: {
  //   msisdn: (val) => {

  //   }
  // },

  methods: {
    create() {
      // console.log('check validate', this.$refs.formCreateJob.validate())
      // if (this.$refs.formCreateJob.validate()){
      //   console.log('validated')
      // } else {
      //   console.log('not validate')
      // }
      // eslint-disable-next-line no-undef
      eventBus.$emit('create', this.jobInfo);
    },

    createJobTest() {
      // this.$refs.form.validate()
      const msisdnEstimate = this.digitalId.split(',');
      this.jobInfo.ids = msisdnEstimate;
      // eslint-disable-next-line no-undef
      eventBus.$emit('createJobTest', this.jobInfo);
    },

    // update() {
    //   // eslint-disable-next-line no-undef
    //   eventBus.$emit('updateCard', this.card);
    // },

    cancel() {
      // eslint-disable-next-line no-undef
      eventBus.$emit('closeSingleForm', false);
    },
  },
};
</script>
